.Col.title {
  max-width: 300px;
}

.Col.content {
  max-width: 500px;
}

.Col.date {
  max-width: 250px;
}

.Col.id,
.Col.action {
  max-width: 120px;
}