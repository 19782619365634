.Col.name {
  max-width: 300px;
}

.Col.address {
  max-width: 500px;
}

.Col.date {
  max-width: 250px;
}

.Col.status,
.Col.action {
  max-width: 120px;
}