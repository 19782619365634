.Layout {
  height: 100vh;
}

.Layout .contentLayout {
  overflow-x: hidden;
  overflow-y: auto;
}

.Layout .breadcrumbRow {
  margin: 12px 24px 0;
  padding-left: 1rem;
  position: relative;
}

.Layout .breadcrumbRow::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 45%;
  border-left: 3px solid #2a132e;
  transform: translateY(-50%);
}

.Sider.normal {
  position: relative;
  box-shadow: 1px 0 10px 1px rgba(0, 0, 0, 0.3);
}

.Sider.mobile {
  position: fixed;
  height: 100vh;
  z-index: 999;
}

.Sider .banner {
  width: 90%;
  position: absolute;
  bottom: 48px;
  left: 50%;
  transform: translate(-50%, -10%);
}

.Sider.collapsed .banner {
  display: none;
}

.Title.mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
}

.Title.normal {
  width: 200px;
  padding: 12px 24px;
}

.Header {
  background-color: transparent;
  height: auto;
  line-height: unset;
  padding: 0;
  box-shadow: 1px 0px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.Header .contentRow {
  background-color: white;
  flex-flow: row nowrap;
  padding: 0 24px;
  height: 48px;
}

.Footer {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}