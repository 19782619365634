/* tailwind like */
.flex {
  display: flex;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-vertical-between {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.overflow-hidden {
  overflow: hidden;
}

.text-center {
  text-align: center;
}

.just-center {
  justify-content: center;
}

.w-full {
  width: 100%;
}

.truncate {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* lib used */
.ant-popover-buttons {
  white-space: nowrap;
}